<template>
  <div class="team">
    <img src="/img/logo.svg" alt="Logo Instituto Burmann & Bastos" class="logo">
    <transition name="page" mode="out-in">
      <div class="d-p">
        <h1>Nossos Profissionais</h1>
        <p class="text-center loading-text" v-if="loading">Carregando...</p>

        <div class="two-cols">
          <div v-for="dr in drs" :key="dr.id" class="dr" @click="openDr(dr.id)">
            <img :src="$mediaUrl + dr.photo" :alt="dr.name">
            <p>{{ dr.name }}</p>
          </div>
        </div>
      </div>
    </transition>

    <div class="dr-modal" v-if="showDrModal">
      <div class="bg" @click="showDrModal = false"></div>
      <div class="modal-content">
        <img :src="$mediaUrl + currDr.photo" :alt="currDr.name">
        <p class="name">{{ currDr.name }}</p>
        <div class="about" v-html="currDr.about"></div>
        <button class="main-button" @click="showDrModal = false">Fechar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '../helpers/http';
import { mask } from 'vue-the-mask'

export default {
  name: 'Team',
  components: {},
  directives: { mask },
  data: () => {
    return {
      loading: true,
      area: {},
      drs: [],
      currDr: {},
      showDrModal: false,
    }
  },
  props: {
    title: String,
    url: String
  },
  methods: {
    openDr(drId) {
      this.showDrModal = true;
      this.currDr = this.drs.filter(d => {
        return d.id === drId;
      })[0]
    }
  },
  async created() {
    HTTP.get('profissionais')
        .then(response => {
          this.loading = false;
          this.drs = response.data.list;
        })
        .catch(err => {
          this.$snotify.error(err.response.data.message, 'Ooops');
        });
  }
}
</script>

<style scoped lang="scss">
  .team {
    padding-top: 25px;
    text-align: center;
    position: relative;

    .logo {
      max-width: 90%;
      width: 150px;
      height: auto;
      position: relative;
    }

    .dr-modal {
      .bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
      }

      .modal-content {
        background-color: #FFFFFF;
        position: fixed;
        top: 10px;
        height: auto;
        left: 25%;
        width: 50%;
        border-radius: 20px;
        padding: 25px;

        @media screen and (max-width: 700px) {
          left: 10px;
          width: calc(100% - 20px);
        }

        img {
          width: 50%;
          height: auto;
          border-radius: 20px;
        }

        .name {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 1.4rem;
        }

        .about {
          text-align: justify;
          text-align-last: center;
        }

        .main-button:hover {
          color: $main-color;
        }
      }
    }

    .two-cols {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 50px;

      @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-gap: 25px;
      }

      .dr {
        cursor: pointer;
        img {
          width: 100%;
          height: 500px;
          object-fit: cover;
          object-position: top center;
          border-radius: 20px;
        }

        p {
          color: #FFFFFF;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 1.5rem;
          margin-top: 5px;
        }
      }
    }
  }
</style>
